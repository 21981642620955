module.exports = {
  WO: { listName: 'Global' },
  AF: { listName: 'Africa' },
  AN: { listName: 'Antarctica' },
  AS: { listName: 'Asia' },
  AT: { listName: 'Atlantis' },
  EU: { listName: 'Europe' },
  NA: { listName: 'North and Central America' },
  OC: { listName: 'Oceania' },
  SA: { listName: 'South America' },

  ASEAN: { listName: 'ASEAN' },
  FE: { listName: 'Pan-European' }, // 'FOREST EUROPE' },
  EU27: { listName: 'EU-27' },
  ECOMIFAC: { listName: 'COMIFAC space' },
  MP: { listName: 'Montreal Process' },
  UNECE: { listName: 'UNECE' },
  ITTO: { listName: 'International Tropical Timber Organization' },

  ABW: { listName: 'Aruba' },
  AFG: { listName: 'Afghanistan' },
  AGO: { listName: 'Angola' },
  AIA: { listName: 'Anguilla' },
  ALB: { listName: 'Albania' },
  AND: { listName: 'Andorra' },
  ARE: { listName: 'United Arab Emirates' },
  ARG: { listName: 'Argentina' },
  ARM: { listName: 'Armenia' },
  ASM: { listName: 'American Samoa' },
  ATG: { listName: 'Antigua and Barbuda' },
  AUS: { listName: 'Australia' },
  AUT: { listName: 'Austria' },
  AZE: { listName: 'Azerbaijan' },
  BDI: { listName: 'Burundi' },
  BEL: { listName: 'Belgium' },
  BEN: { listName: 'Benin' },
  BES: { listName: 'Bonaire, Sint Eustatius and Saba' },
  BFA: { listName: 'Burkina Faso' },
  BGD: { listName: 'Bangladesh' },
  BGR: { listName: 'Bulgaria' },
  BHR: { listName: 'Bahrain' },
  BHS: { listName: 'Bahamas' },
  BIH: { listName: 'Bosnia and Herzegovina' },
  BLM: { listName: 'Saint Barthélemy' },
  BLR: { listName: 'Belarus' },
  BLZ: { listName: 'Belize' },
  BMU: { listName: 'Bermuda' },
  BOL: { listName: 'Bolivia (Plurinational State of)' },
  BRA: { listName: 'Brazil' },
  BRB: { listName: 'Barbados' },
  BRN: { listName: 'Brunei Darussalam' },
  BTN: { listName: 'Bhutan' },
  BWA: { listName: 'Botswana' },
  CAF: { listName: 'Central African Republic' },
  CAN: { listName: 'Canada' },
  CHE: { listName: 'Switzerland' },
  CHL: { listName: 'Chile' },
  CHN: { listName: 'China' },
  CIV: { listName: "Côte d'Ivoire" },
  CMR: { listName: 'Cameroon' },
  COD: { listName: 'Democratic Republic of the Congo' },
  COG: { listName: 'Congo' },
  COK: { listName: 'Cook Islands' },
  COL: { listName: 'Colombia' },
  COM: { listName: 'Comoros' },
  CPV: { listName: 'Cabo Verde' },
  CRI: { listName: 'Costa Rica' },
  CUB: { listName: 'Cuba' },
  CUW: { listName: 'Curaçao' },
  CYM: { listName: 'Cayman Islands' },
  CYP: { listName: 'Cyprus' },
  CZE: { listName: 'Czechia' },
  DEU: { listName: 'Germany' },
  DJI: { listName: 'Djibouti' },
  DMA: { listName: 'Dominica' },
  DNK: { listName: 'Denmark' },
  DOM: { listName: 'Dominican Republic' },
  DZA: { listName: 'Algeria' },
  ECU: { listName: 'Ecuador' },
  EGY: { listName: 'Egypt' },
  ERI: { listName: 'Eritrea' },
  ESH: { listName: 'Western Sahara' },
  ESP: { listName: 'Spain' },
  EST: { listName: 'Estonia' },
  ETH: { listName: 'Ethiopia' },
  FIN: { listName: 'Finland' },
  FJI: { listName: 'Fiji' },
  FLK: { listName: 'Falkland Islands (Malvinas)' },
  FRA: { listName: 'France' },
  FRO: { listName: 'Faroe Islands' },
  FSM: { listName: 'Micronesia (Federated States of)' },
  GAB: { listName: 'Gabon' },
  GBR: { listName: 'United Kingdom of Great Britain and Northern Ireland' },
  GEO: { listName: 'Georgia' },
  GGY: { listName: 'Guernsey' },
  GHA: { listName: 'Ghana' },
  GIB: { listName: 'Gibraltar' },
  GIN: { listName: 'Guinea' },
  GLP: { listName: 'Guadeloupe' },
  GMB: { listName: 'Gambia' },
  GNB: { listName: 'Guinea-Bissau' },
  GNQ: { listName: 'Equatorial Guinea' },
  GRC: { listName: 'Greece' },
  GRD: { listName: 'Grenada' },
  GRL: { listName: 'Greenland' },
  GTM: { listName: 'Guatemala' },
  GUF: { listName: 'French Guyana' },
  GUM: { listName: 'Guam' },
  GUY: { listName: 'Guyana' },
  HND: { listName: 'Honduras' },
  HRV: { listName: 'Croatia' },
  HTI: { listName: 'Haiti' },
  HUN: { listName: 'Hungary' },
  IDN: { listName: 'Indonesia' },
  IMN: { listName: 'Isle of Man' },
  IND: { listName: 'India' },
  IRL: { listName: 'Ireland' },
  IRN: { listName: 'Iran (Islamic Republic of)' },
  IRQ: { listName: 'Iraq' },
  ISL: { listName: 'Iceland' },
  ISR: { listName: 'Israel' },
  ITA: { listName: 'Italy' },
  JAM: { listName: 'Jamaica' },
  JEY: { listName: 'Jersey' },
  JOR: { listName: 'Jordan' },
  JPN: { listName: 'Japan' },
  KAZ: { listName: 'Kazakhstan' },
  KEN: { listName: 'Kenya' },
  KGZ: { listName: 'Kyrgyzstan' },
  KHM: { listName: 'Cambodia' },
  KIR: { listName: 'Kiribati' },
  KNA: { listName: 'Saint Kitts and Nevis' },
  KOR: { listName: 'Republic of Korea' },
  KWT: { listName: 'Kuwait' },
  LAO: { listName: "Lao People's Democratic Republic" },
  LBN: { listName: 'Lebanon' },
  LBR: { listName: 'Liberia' },
  LBY: { listName: 'Libya' },
  LCA: { listName: 'Saint Lucia' },
  LIE: { listName: 'Liechtenstein' },
  LKA: { listName: 'Sri Lanka' },
  LSO: { listName: 'Lesotho' },
  LTU: { listName: 'Lithuania' },
  LUX: { listName: 'Luxembourg' },
  LVA: { listName: 'Latvia' },
  MAF: { listName: 'Saint-Martin (French Part)' },
  MAR: { listName: 'Morocco' },
  MCO: { listName: 'Monaco' },
  MDA: { listName: 'Republic of Moldova' },
  MDG: { listName: 'Madagascar' },
  MDV: { listName: 'Maldives' },
  MEX: { listName: 'Mexico' },
  MHL: { listName: 'Marshall Islands' },
  MKD: { listName: 'North Macedonia' },
  MLI: { listName: 'Mali' },
  MLT: { listName: 'Malta' },
  MMR: { listName: 'Myanmar' },
  MNE: { listName: 'Montenegro' },
  MNG: { listName: 'Mongolia' },
  MNP: { listName: 'Northern Mariana Islands' },
  MOZ: { listName: 'Mozambique' },
  MRT: { listName: 'Mauritania' },
  MSR: { listName: 'Montserrat' },
  MTQ: { listName: 'Martinique' },
  MUS: { listName: 'Mauritius' },
  MWI: { listName: 'Malawi' },
  MYS: { listName: 'Malaysia' },
  MYT: { listName: 'Mayotte' },
  NAM: { listName: 'Namibia' },
  NCL: { listName: 'New Caledonia' },
  NER: { listName: 'Niger' },
  NFK: { listName: 'Norfolk Island' },
  NGA: { listName: 'Nigeria' },
  NIC: { listName: 'Nicaragua' },
  NIU: { listName: 'Niue' },
  NLD: { listName: 'Netherlands (Kingdom of the)' },
  NOR: { listName: 'Norway' },
  NPL: { listName: 'Nepal' },
  NRU: { listName: 'Nauru' },
  NZL: { listName: 'New Zealand' },
  OMN: { listName: 'Oman' },
  PAK: { listName: 'Pakistan' },
  PAN: { listName: 'Panama' },
  PCN: { listName: 'Pitcairn' },
  PER: { listName: 'Peru' },
  PHL: { listName: 'Philippines' },
  PLW: { listName: 'Palau' },
  PNG: { listName: 'Papua New Guinea' },
  POL: { listName: 'Poland' },
  PRI: { listName: 'Puerto Rico' },
  PRK: { listName: "Democratic People's Republic of Korea" },
  PRT: { listName: 'Portugal' },
  PRY: { listName: 'Paraguay' },
  PSE: { listName: 'Palestine' },
  PYF: { listName: 'French Polynesia' },
  QAT: { listName: 'Qatar' },
  REU: { listName: 'Réunion' },
  ROU: { listName: 'Romania' },
  RUS: { listName: 'Russian Federation' },
  RWA: { listName: 'Rwanda' },
  SAU: { listName: 'Saudi Arabia' },
  SDN: { listName: 'Sudan' },
  SEN: { listName: 'Senegal' },
  SGP: { listName: 'Singapore' },
  SHN: { listName: 'Saint Helena' },
  SJM: { listName: 'Svalbard and Jan Mayen Islands' },
  SLB: { listName: 'Solomon Islands' },
  SLE: { listName: 'Sierra Leone' },
  SLV: { listName: 'El Salvador' },
  SMR: { listName: 'San Marino' },
  SOM: { listName: 'Somalia' },
  SPM: { listName: 'Saint Pierre and Miquelon' },
  SRB: { listName: 'Serbia' },
  SSD: { listName: 'South Sudan' },
  STP: { listName: 'Sao Tome and Principe' },
  SUR: { listName: 'Suriname' },
  SVK: { listName: 'Slovakia' },
  SVN: { listName: 'Slovenia' },
  SWE: { listName: 'Sweden' },
  SWZ: { listName: 'Eswatini' },
  SXM: { listName: 'Sint Maarten (Dutch part)' },
  SYC: { listName: 'Seychelles' },
  SYR: { listName: 'Syrian Arab Republic' },
  TCA: { listName: 'Turks and Caicos Islands' },
  TCD: { listName: 'Chad' },
  TGO: { listName: 'Togo' },
  THA: { listName: 'Thailand' },
  TJK: { listName: 'Tajikistan' },
  TKL: { listName: 'Tokelau' },
  TKM: { listName: 'Turkmenistan' },
  TLS: { listName: 'Timor-Leste' },
  TON: { listName: 'Tonga' },
  TTO: { listName: 'Trinidad and Tobago' },
  TUN: { listName: 'Tunisia' },
  TUR: { listName: 'Türkiye' },
  TUV: { listName: 'Tuvalu' },
  TZA: { listName: 'United Republic of Tanzania' },
  UGA: { listName: 'Uganda' },
  UKR: { listName: 'Ukraine' },
  URY: { listName: 'Uruguay' },
  USA: { listName: 'United States of America' },
  UZB: { listName: 'Uzbekistan' },
  VAT: { listName: 'Holy See' },
  VCT: { listName: 'Saint Vincent and the Grenadines' },
  VEN: { listName: 'Venezuela (Bolivarian Republic of)' },
  VGB: { listName: 'British Virgin Islands' },
  VIR: { listName: 'United States Virgin Islands' },
  VNM: { listName: 'Viet Nam' },
  VUT: { listName: 'Vanuatu' },
  WLF: { listName: 'Wallis and Futuna Islands' },
  WSM: { listName: 'Samoa' },
  X01: { listName: 'Atlantis 01' },
  X02: { listName: 'Atlantis 02' },
  X03: { listName: 'Atlantis 03' },
  X04: { listName: 'Atlantis 04' },
  X05: { listName: 'Atlantis 05' },
  X06: { listName: 'Atlantis 06' },
  X07: { listName: 'Atlantis 07' },
  X08: { listName: 'Atlantis 08' },
  X09: { listName: 'Atlantis 09' },
  X10: { listName: 'Atlantis 10' },
  X11: { listName: 'Atlantis 11' },
  X12: { listName: 'Atlantis 12' },
  X13: { listName: 'Atlantis 13' },
  X14: { listName: 'Atlantis 14' },
  X15: { listName: 'Atlantis 15' },
  X16: { listName: 'Atlantis 16' },
  X17: { listName: 'Atlantis 17' },
  X18: { listName: 'Atlantis 18' },
  X19: { listName: 'Atlantis 19' },
  X20: { listName: 'Atlantis 20' },
  YEM: { listName: 'Yemen' },
  ZAF: { listName: 'South Africa' },
  ZMB: { listName: 'Zambia' },
  ZWE: { listName: 'Zimbabwe' },
}
